<template>
  <div v-if="load == 1">
    <div class="headerRdvAac">
        <h1>{{eleve.fullname}}</h1>
        <button class="btn btn-primary" @click="backToTrame">
                Retour à la trame
        </button>

        <button class="btn btn-primary" @click="finishCours">
            FIN DU COURS
        </button>
    </div>
    <div class="table">
        <h4>{{ apNext }}</h4>
        <DataTable :value="apointments" tableStyle="width: 100%">
            <Column field="code" header="">
              <template #body="{ data }">
                <input type="radio" name="eleve" :id="data.id" :value="data.id" v-model="idApAac">
              </template>
            </Column>
            <Column field="ladate" header="date"></Column>
            <Column field="heure" header="heure"></Column>
            <Column field="moniteur.fullname" header="Moniteur"></Column>
            <Column field="lieuDeb.nom" header="Début"></Column>
            <Column field="lieuFin.nom" header="Fin"></Column>
        </DataTable>
        <br><br>
        <button class="btn btn-primary" v-if="idApAac != 0" @click="ValiderApAac">
                VALIDER
        </button>
        <br><br><br>
        <button class="btn btn-primary" @click="putAacNextAp">
            PROCHAIN COURS = {{ apNext }}
        </button>
    </div>

  </div>
</template>

<script>
export default {

    props: [
        "apNext",
        "goBackToTrame",
        "endCours"
    ],

  components: {},

  watch: {},

  data() {
    return {
        eleve:[],
        apointments: [],
        formationAac: 0,
        load : 0,
        idApAac: 0
    };
  },

  mounted() {
    this.getEleve();
  },

  methods: {

    ValiderApAac(){
      let categ = "";
      if(this.apNext == "RDV préalable"){
        categ = "rdvp";
      }else if(this.apNext == "RDV pédagogique 1"){
        categ = "rdvp1";
      }else if(this.apNext == "RDV pédagogique 2"){
        categ = "rdvp2";
      }

      window.api
          .patch(
            "/apointments/" + this.idApAac,
            {
              statutAac: categ,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            this.$emit("goBackToTrame");
          })
          .catch((error) => {
            console.log(error);
          });
    },

    putAacNextAp(){
      if(this.apNext == "RDV préalable"){
        window.api
          .patch(
            "/formation_aacs/" + this.formationAac,
            {
              rdvp: true,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            this.$emit("goBackToTrame");
          })
          .catch((error) => {
            console.log(error);
          });
      }else if(this.apNext == "RDV pédagogique 1"){
        window.api
          .patch(
            "/formation_aacs/" + this.formationAac,
            {
              rdvp1: true,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            this.$emit("goBackToTrame");
          })
          .catch((error) => {
            console.log(error);
          });
      }else if(this.apNext == "RDV pédagogique 2"){
        window.api
          .patch(
            "/formation_aacs/" + this.formationAac,
            {
              rdvp2: true,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            this.$emit("goBackToTrame");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },


    backToTrame(){
      this.$emit("goBackToTrame");
    },

    finishCours(){
      this.$emit("endCours");
    },

    getEleve(){
        window.api
        .get("/eleve/aac/rdvs/" + this.$route.params.id+"/"+this.$route.params.idRdv, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.eleve = response.data.eleve;
          this.apointments = response.data.apointments;
          this.formationAac = response.data.formationAac;
          this.load = this.load + 1;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>

<style scoped>

div.headerRdvAac{
    display: flex;
    margin-top: 2em;
    justify-content: space-around;
    margin-bottom: 2em;
}

div.table{
    width: 80%;
    margin: auto;
}

div.table h4{
margin-bottom: 2em;
}

</style>
