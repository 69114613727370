<template>
  <card style="width: 80%; margin: auto; margin-top: 3em;" v-if="loadPage == 2">
    <template #content>
      <div >
          <div class="boite">
              <h4 class="titleMoniteurs">1/ Choisissez le type de véhicule</h4>
              <div class="choice">
                  <label class="container">Boîte Manuelle (20h minimum - {{prixBm}}€ /h)
                      <input type="radio" checked="checked" name="radio" value="BM" v-model="boite">
                      <span class="checkmark"></span>
                  </label>
                  <label class="container">Boîte Automatique (13h minimum - {{prixBa}}€ /h)
                      <input type="radio" name="radio" value="BA" v-model="boite">
                      <span class="checkmark"></span>
                  </label>
              </div>
          </div>

          <div class="moniteur">
              <h4 class="titleMoniteurs">2/ Choisissez vos moniteurs et vos lieux de RDV (3 monos maximum)</h4>
              <!---
              <div class="tables">
                <div class="mono" v-for="m in moniteurs" :key="m.id">

                  <div class="nom">
                    <input type="checkbox" :id="m.idMono" :name="m.fullname" :value="m.idMono" @click="setMonoEleve">
                    <label >{{ m.fullname }}</label>
                    
                  </div>
                  <template v-for="l in m.lieux" :key="l.id">
                    <div class="lieu" v-if="l.get == true" >
                      <input type="checkbox" :id="l.id" :name="m.idMono" :value="l.id" @click="setLieuEleve">
                      <label >{{ l.nom }}</label>
                    </div>
                  </template>

                </div>
              </div>
              ---->
              <div class="choice">
                <card style="width: 48%; overflow: scroll; height: 400px;" class="moniteurList">
                  <template #content>
                    <h4 class="sstitre">Choix des moniteurs</h4>
                    <template v-for="m in moniteurs" :key="m.id">
                      <template v-if="m.choixEleve == true">
                        <button class="btn btn-success" name="monoBtn" :id="m.idMono" @click="setMono">{{ m.fullname }}</button> <button class="showLieux btn btn-primary " @click="showLieuxMono" :id="m.idMono">Lieux</button><br>
                      </template>
                      <template v-else>
                        <button class="btn btn-secondary" name="monoBtn" :id="m.idMono" @click="setMono">{{ m.fullname }}</button> <button class="showLieux btn btn btn-primary " @click="showLieuxMono" :id="m.idMono">Lieux</button><br>
                      </template>
                          
                    </template>
                  </template>

                </card>

                <card style="width: 48%; overflow: scroll; height: 400px;" class="lieuList">
                  <template #content>
                    <h4 class="sstitre">Choix des lieux</h4>
                    <template v-if="lieuMoniteur.length != 0">
                      <template v-for="l in lieuMoniteur" :key="l.id">
                        <template v-if="l.choixEleve == true">
                          <button class="lieuBtn btn btn-success" :id="l.id" v-if="l.get == true" :name="monoSelect.idMono" @click="setLieu">{{ l.nom }}</button> <br>
                        </template>
                        <template v-else>
                          <button class="lieuBtn btn btn-secondary" :id="l.id" v-if="l.get == true" :name="monoSelect.idMono" @click="setLieu">{{ l.nom }}</button> <br>
                        </template>
                        
                      </template>
                    </template>
                  </template>

                </card>
              </div>


          </div>
          <br>
          <button  class="btn btn-primary" @click="setChoixEleve">VALIDER</button>
      </div>
    </template>
  </card>
  <template v-else>
    <div>
      <Loader/>
    </div>
  </template>
</template>
  
  <script>
  import Loader from "@/components/Loader.vue";
  export default {
  
      props: [
        "eleve",
        "choixDispo",
        "getEleveInfo",
        "closeModalChoixEleve",
        "setNewChoixDispo",
        "modalChoixEleve"
      ],
  
    components: {
      Loader
    },
  

  
    data() {
      return {
        moniteurs : [],
        unMoniteurs : [],
        choixMono : [],
        lieuMoniteur : [],
        monoSelect : null,
        loadMono : 0,
        boite : "BM",
        prixBm : 0,
        prixBa : 0,
        loadPage : 0,
      };
    },
  
    mounted() {
        if(this.choixDispo != "n" && this.choixDispo != null && this.choixDispo != []){
          this.choixMono = this.choixDispo.choix;
        }
        
        this.getFonctionnalite();
        this.getMoniteurs();

        if(this.eleve.boite == 1){
          this.boite = "BA"
        }else{
          this.boite = "BM"
        }
    },
  
    methods: {

      showLieuxMono(e){

        let lieuBtnTarget = null;
        for(let lb of document.getElementsByClassName("showLieux")){
          if(e.target.id == lb.id){
            lieuBtnTarget = lb;
          }
        }

        if(e.target["classList"][2] == "btn-primary"){
          lieuBtnTarget.classList.remove(e.target["classList"][2]);
          lieuBtnTarget.classList.add("btn-info");

          this.moniteurs.forEach(m => {
            if(e.target.id == m.idMono){
              this.monoSelect = m;
              this.lieuMoniteur = m.lieux
            }
          })

          this.lieuMoniteur.forEach(l => {
            l.choixEleve = false;
          })


          if(this.choixMono != "n" && this.choixMono != null && this.choixMono != []){
              this.lieuMoniteur.forEach(l => {
                this.choixMono.forEach(c => {
                  if(c.id == e.target.id){
                    if(c.lieux != undefined){
                      c.lieux.forEach(li => {
                        if(l.id == li){
                          l.choixEleve = true;
                        }
                      })
                    }
                  }
                })
              })
          }


        }else{
          lieuBtnTarget.classList.remove(e.target["classList"][2]);
          lieuBtnTarget.classList.add("btn-primary");
          this.lieuMoniteur = [];
          this.monoSelect = null;
        }
      },

      setLieu(e){

        let lieuBtnTarget = null;
        for(let lb of document.getElementsByClassName("lieuBtn")){
          if(e.target.id == lb.id){
            lieuBtnTarget = lb;
          }
        }
        
        if(e.target["classList"][2] == "btn-secondary"){
          lieuBtnTarget.classList.remove(e.target["classList"][2]);
          lieuBtnTarget.classList.add("btn-success");
          let findMono = false;
          let tab = [];
          this.choixMono.forEach(c => {
            if(c.id == e.target.name){
              if(c.lieux == undefined){
                c.lieux = [];
              }
              c.lieux.push(e.target.id);
              findMono = true;
            }
            tab.push(c);
          })


          if(!findMono){
            if(this.choixMono.length < 3){
              for(let mb of document.getElementsByName("monoBtn")){
                if(mb.id == e.target.name){

                  mb.classList.remove(mb["classList"][1]);
                  mb.classList.add("btn-success");
                  this.choixMono.push({"id" : mb.id})

                  this.choixMono.forEach(cd => {
                    if(cd.id == e.target.name){
                      
                      if(cd.lieux == undefined){
                        cd.lieux = [];
                      }
                      cd.lieux.push(e.target.id);
                      tab.push(cd)
                    }
                    
                  })

                }
              }
            }else{

              let choixMonoTab = [];
              this.choixMono.forEach(c => {
                let monoFind = null;

                monoFind = this.unMoniteurs.find(e => e.idMono == c.id);

                if(monoFind == null || monoFind == undefined){
                  choixMonoTab.push(c);
                }

              })

              this.choixMono = choixMonoTab;

              if(this.choixMono.length < 3){
                for(let mb of document.getElementsByName("monoBtn")){
                  if(mb.id == e.target.name){

                    mb.classList.remove(mb["classList"][1]);
                    mb.classList.add("btn-success");
                    this.choixMono.push({"id" : mb.id})

                    this.choixMono.forEach(cd => {
                      if(cd.id == e.target.name){
                        
                        if(cd.lieux == undefined){
                          cd.lieux = [];
                        }
                        cd.lieux.push(e.target.id);
                        tab.push(cd)
                      }
                      
                    })

                  }
                }
              }else{
                alert("Vous êtes limité à 3 moniteurs maximum, si vous voulez vraiment choisir ce moniteur veuillez sélectionner un autre moniteur");
              }

              
            }

          }


          this.choixMono = tab;

        }else{
          lieuBtnTarget.classList.remove(e.target["classList"][2]);
          lieuBtnTarget.classList.add("btn-secondary");

          let tab = [];
          this.choixMono.forEach(c => {
            let c2 = [];
            if(c.id == e.target.name){
              let ok = false;
              if(c.lieux.length == 1){
                
                ok = true;
              }
              let tabl = [];
              c.lieux.forEach(l => {
                if(l != e.target.id){
                  tabl.push(l);
                }
              })

              if(!ok){
                c2.lieux = tabl;
                c2.id = c.id;
                tab.push(c2);
              }
              c.lieux.push(e.target.id);
            }else{
              tab.push(c);
            }
            
          })

          this.choixMono = tab;

        }
      },

      setMono(e){
        if(e.target["classList"][1] == "btn-secondary"){
          if(this.choixMono.length < 3){
            document.getElementById(e.target.id).classList.remove(e.target["classList"][1]);
            document.getElementById(e.target.id).classList.add("btn-success");
            this.choixMono.push({"id" : e.target.id})
            
            this.moniteurs.forEach(m => {
              if(e.target.id == m.idMono){
                this.monoSelect = m;
                this.lieuMoniteur = m.lieux
              }
            })

            this.lieuMoniteur.forEach(l => {
              l.choixEleve = false;
            })


            if(this.choixMono != "n" && this.choixMono != null && this.choixMono != []){
                this.lieuMoniteur.forEach(l => {
                  this.choixMono.forEach(c => {
                    if(c.id == e.target.id){
                      if(c.lieux != undefined){
                        c.lieux.forEach(li => {
                          if(l.id == li){
                            l.choixEleve = true;
                          }
                        })
                      }
                    }
                  })
                })
            }
          }else{

            let choixMonoTab = [];
            this.choixMono.forEach(c => {
              let monoFind = null;

              monoFind = this.unMoniteurs.find(e => e.idMono == c.id);

              if(monoFind == null || monoFind == undefined){
                choixMonoTab.push(c);
              }

            })

            this.choixMono = choixMonoTab;

            if(this.choixMono.length < 3){
              document.getElementById(e.target.id).classList.remove(e.target["classList"][1]);
              document.getElementById(e.target.id).classList.add("btn-success");
              this.choixMono.push({"id" : e.target.id})
              
              this.moniteurs.forEach(m => {
                if(e.target.id == m.idMono){
                  this.monoSelect = m;
                  this.lieuMoniteur = m.lieux
                }
              })

              this.lieuMoniteur.forEach(l => {
                l.choixEleve = false;
              })


              if(this.choixMono != "n" && this.choixMono != null && this.choixMono != []){
                  this.lieuMoniteur.forEach(l => {
                    this.choixMono.forEach(c => {
                      if(c.id == e.target.id){
                        if(c.lieux != undefined){
                          c.lieux.forEach(li => {
                            if(l.id == li){
                              l.choixEleve = true;
                            }
                          })
                        }
                      }
                    })
                  })
              }
            }else{
              alert("Vous êtes limité à 3 moniteurs maximum, si vous voulez vraiment choisir ce moniteur veuillez sélectionner un autre moniteur");
            }



            
          }





        }else{
          document.getElementById(e.target.id).classList.remove(e.target["classList"][1]);
          document.getElementById(e.target.id).classList.add("btn-secondary");
          this.lieuMoniteur = [];
          this.monoSelect = null;
          let tab = [];
          this.choixMono.forEach(c => {
            if(c.id != e.target.id){
              tab.push({"id" : c.id, "lieux" : c.lieux})
            }else{
              if(c.lieux != undefined){
                c.lieux.forEach(l => {
                  this.lieuMoniteur.forEach(li => {
                    if(l == li.id){
                      li.choixEleve = false;
                    }
                  })
                })
              }
            }
          })
          this.choixMono = tab;
        }

      },

      getFonctionnalite() {
        window.api
          .get("/fonctionnalites/", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.loadPage++;
            response.data["hydra:member"].forEach((fonc) => {
              if (fonc.label === "conduite BM") {
                this.prixBm = fonc.montant;
              } else if (fonc.label === "conduite BA") {
                this.prixBa = fonc.montant;
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      },


      setChoixEleve(){
        if(this.choixDispo == "n"){
          let idBoite = 1;
          if(this.boite == "BM"){
            idBoite = 2;
          }
          window.api
          .patch(
            "/utilisateur/" + this.eleve.id,
            {
              boite: idBoite,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            window.api
                .post(
                  "/choix_dispo_eleves",
                  {
                    eleve: "/api/utilisateurs/"+this.eleve.id,
                    choix: this.choixMono
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then((response) => {
                  this.$store.commit("setShowMonoElevePage", false);
                  this.$emit("getEleveInfo");
                  if(this.modalChoixEleve == true){
                    this.$emit("closeModalChoixEleve");
                    this.$emit("setNewChoixDispo", {"id" : response.data.id, "choix" : response.data.choix});
                  }
                  
                })
                .catch((error) => {
                  console.log(error);
                });
          })
          .catch((error) => {
            console.log(error);
          });


        }else{

          let idBoite = 1;
          if(this.boite == "BM"){
            idBoite = 2;
          }
          window.api
          .patch(
            "/utilisateur/" + this.eleve.id,
            {
              boite: idBoite,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            window.api
                .put(
                  "/choix_dispo_eleves/"+this.choixDispo.id,
                  {
                    eleve: "/api/utilisateurs/"+this.eleve.id,
                    choix: this.choixMono
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then((response) => {
                  this.$store.commit("setShowMonoElevePage", false);
                  this.$emit("getEleveInfo");
                  if(this.modalChoixEleve == true){
                    this.$emit("closeModalChoixEleve");
                    this.$emit("setNewChoixDispo", {"id" : response.data.id, "choix" : response.data.choix});
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
          })
          .catch((error) => {
            console.log(error);
          });


        }


      },

      setLieuEleve(e){
        if(e.target.checked){
          let els = document.getElementsByClassName("nom");
          Array.prototype.forEach.call(els, function(el) {
              if(el.children[0].id == e.target.name){
                el.children[0].checked = true;
              }
          });

          if(document.getElementById(e.target.name).checked == false){
            this.choixMono.push({"id" : e.target.id})
          }
          let tab = [];
          this.choixMono.forEach(c => {
            if(c.id == e.target.name){
              if(c.lieux == undefined){
                c.lieux = [];
              }
              c.lieux.push(e.target.id);
            }
            tab.push(c);
          })

          this.choixMono = tab;
        }else{
          let tab = [];
          this.choixMono.forEach(c => {
            let c2 = [];
            if(c.id == e.target.name){
              let ok = false;
              if(c.lieux.length == 1){
                
                ok = true;
              }
              let tabl = [];
              c.lieux.forEach(l => {
                if(l != e.target.id){
                  tabl.push(l);
                }
              })

              if(!ok){
                c2.lieux = tabl;
                c2.id = c.id;
                tab.push(c2);
              }else{
                let els = document.getElementsByClassName("nom");
                Array.prototype.forEach.call(els, function(el) {
                    if(el.children[0].id == e.target.name){
                      el.children[0].checked = false;
                    }
                });
              }
              c.lieux.push(e.target.id);
            }else{
              tab.push(c);
            }
            
          })

          this.choixMono = tab;
          
        }
      },

      setMonoEleve(e){
        if(e.target.checked){
          this.choixMono.push({"id" : e.target.id})
        }else{
          let tab = [];
          this.choixMono.forEach(c => {
            if(c.id != e.target.id){
              tab.push({"id" : e.target.id})
            }else{
              if(c.lieux != undefined){
                c.lieux.forEach(l => {
                  document.getElementById(l).checked = false;
                })
              }
            }
          })

          this.choixMono = tab;
          
        }
      },

      getMoniteurs(){
        window.api
        .get("/moniteurs/agence/planning/" + this.$store.state.user.agence.id + "/null", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
            this.loadPage++;
            this.moniteurs = response.data.moniteurs;
            this.unMoniteurs = response.data.unMoniteurs;
            
            this.loadMono++;

            this.moniteurs.forEach(m => {
              m.choixEleve = false;
            })

            if(this.choixDispo != "n" && this.choixDispo != null && this.choixDispo != []){
              this.moniteurs.forEach(m => {
                this.choixDispo.choix.forEach(c => {
                  if(c.id == m.idMono){
                    m.choixEleve = true;
                  }
                })
              })
            }
        })
        .catch((error) => {
          console.log(error);
        });
      }

    }
  };
  </script>
  
  <style scoped>

  div.choice{
    display: flex;
    justify-content: space-around;
  }



  h4.sstitre{
    text-align: left;
    padding: 1%;
    font-weight: bold;
  }

  .moniteurList button{
    margin: 1em;
    width: 60%;
  }

  .moniteurList button.showLieux{
    width: auto;
  }

  .lieuList button{
    margin: 1em;
    width: 60%;
  }



  div.tables{

    display: flex;
  }

  div.mono{
    border-bottom : 1px solid #f8f9fa;
    
    width: 300px;
  }

  div.nom{
    background-color: #f8f9fa;
  }

  div.lieu{
    border-bottom : 1px solid #f8f9fa;
    background-color: #ffffff;
    
  }
  
div.backgroundMoniteur{
    width: 90%;
    border: 1px black solid;
    border-radius: 1%;
    margin: auto;
    margin-top: 3em;
    height: 70vh;
    box-shadow: 0px 0 5px rgb(0, 0, 0);
    background-color: white;
    padding: 2em;
    overflow:scroll;
}

div.moniteur{
    margin-top: 2em;
    height: 100%;
    overflow:visible;
}

h4.titleMoniteurs{
    text-align: left;
    font-weight: bold;
}

div.choice{
    margin-top: 2em;
    display: flex;
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  text-align: left;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

  
  </style>
  