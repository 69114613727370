<template>
  <div class="header">
    <img @click="goToHome()" class="gohome" :src="require('../../../assets/images/logoMonPermis.png')" />
    <div class="control">
      <div class="flex">
        <label class="container">
          <input type="checkbox" @click="setAuto" v-model="auto"/>
          AUTO
        </label>

        <label class="container">
          <input type="checkbox" @click="choiceIndipo" v-model="indispoChoice"/>
          INDISPO
        </label>
      </div>
      <div class="button">
        <button class="btn btn-primary" @click="showMono(false)">MONO</button>
        <button class="btn btn-primary" @click="showMono(true)">Edits MONO</button>
        <button class="btn btn-primary" @click="showDroits">DROITS</button>
        <button class="btn btn-primary" @click="showSimu">SIMU</button>
      </div>
    </div>
    <div class="eleve">
      <div class="containername">
        <SimpleTypeahead
          id="typeahead_id"
          placeholder="Rechercher un élève..."
          :items="leleves"
          :minInputLength="1"
          :itemProjection="
            (item) => {
              return item.fullname;
            }
          "
          @selectItem="selectItem"
          @onInput="searchEleve"
        >
        </SimpleTypeahead>
        <button class=" btn-primary" v-if="showBtn" @click="cancelChoice">X</button>
      </div>

      <div class="flex">

          <label class="container">
            <input type="checkbox" @click="choiceDispo" v-model="dispoChoice"/>
            DISPO
          </label>

          <label class="container">
            <input type="checkbox" @click="choiceResa" v-model="resaChoice"/>
            RESA
          </label>

      </div>
    </div>

    <div class="select" :class="{ hidding: !showBtn }">
      <button class="btn btn-primary" @click="showSolde">SOLDE {{eleve.solde}}€</button>
      <button class="btn btn-primary" @click="showPaiement"> <img class="logoPaiement" src="../../../assets/images/paiement/de-largent.png" alt="" /> </button>
      <br />
      <button class="btn btn-primary" @click="openModalChoixEleve">Choix</button>
      <template v-if="this.eleve.acc == true">
        <button class="btn btn-success" @click="cancelAcc">ACC</button>
      </template>
      <template v-else>
        <button class="btn btn-primary" @click="showAcc">ACC ?</button>
      </template>

      <button class="btn btn-primary" @click="goToFicheEleve">FICHE</button>

      <template v-if="this.eleve.aac">
        <button class="btn btn-success" @click="unSetAac">AAC</button>
      </template>
      <template v-else>
        <button class="btn btn-primary" @click="showAac">AAC ?</button>
      </template>
      <template v-if="eleve.boite == null">
        <button class="btn btn-primary" @click="showBoite">BA OU BM ?</button>
      </template>
      
      <template v-else-if="eleve.boite == 1">
        <button class="btn btn-success" @click="changeBoite(2)">BA</button>
      </template>
      <template v-else-if="eleve.boite == 2">
        <button class="btn btn-danger" @click="changeBoite(1)">BM</button>
      </template>
      
    </div>

    <Dialog v-model:visible="showSimuPopup" modal header="Simulateur - Liste des élèves" :style="{ width: '80rem' }">

      <div class="selector">
        <select name="getCode" id="getCode" class="selectSimu" @click="getCodeResultSimu">
          <option value="null">Resultat Code</option>
          <option value="true">Oui</option>
          <option value="false">Non</option>
        </select>

        <div class="containername">
          <SimpleTypeahead
            id="typeahead_id"
            placeholder="Rechercher un élève..."
            :items="simuEleves"
            :minInputLength="1"
            :itemProjection="
              (item) => {
                return item.fullname;
              }
            "
            @selectItem="selectItemSimu"
          >
          </SimpleTypeahead>
          <button class=" btn-primary" v-if="showCancelBtnSimu" @click="cancelChoiceSimu">X</button>
        </div>

      </div>



      <DataTable           
          :value="this.simuEleves"
          v-model:selection="eleveSelectSimu"
          selectionMode="single"
          tableStyle="min-width: 80rem"
          @click="selectSimu"
          paginator
          :rows="10"
          :rowsPerPageOptions="[10, 20, 50]">
        <Column field="fullname" header="Nom"  ></Column>
        <Column field="scoreDerniersTest" header="Score 5 derniers tests"  sortable></Column>
        <Column field="code" header="Resultat Code"  ></Column>
        <Column field="nbRdv" header="Nombre de RDV restant"  ></Column>
      </DataTable>
    </Dialog>

    <Dialog v-model:visible="droitBtn" modal header="Droits" :style="{ width: '50rem' }">
      <div class="droitsModal">
        <p>
          Droits =
        </p>
        <input type="number" name="droit" id="droit" v-model="droitNumber">
        <p>Heures/Semaine</p>
      </div>
      <template v-if="loadDroitBtn == false">
        <button class="btn btn-primary btnValideDroit" @click="setDroit">VALIDER</button>
      </template>
      <template v-else>
        <button @click="setDroit()" class="btn btn-primary btnValideDroit" disabled><span><i class="fa fa-spinner fa-spin"></i>VALIDER</span></button>
      </template>
      
    </Dialog>

    <Dialog v-model:visible="soldeBtn" modal :header="headerSolde" :style="{ width: '50%' }">

      <template #header>
        <div class="headSolde">
          <h3>{{ this.eleve.fullname }}  {{this.eleve.solde}}€</h3>
          <button class="btn btn-primary btnSwitchSolde" @click="changeSoldBtn" v-if="soldeSoldebtn">Heures ></button>
          <button class="btn btn-primary btnSwitchSolde" @click="changeSoldBtn" v-if="soldeRdvBtn">&lt; Solde</button>
        </div>
      </template>

      <div class="soldePage" v-if="soldeSoldebtn">
        <DataTable :value="eleve.paiements" tableStyle="min-width: 50rem">
          <Column field="date" header="Date"></Column>
          <Column field="prestation" header="Préstation"></Column>
          <Column field="montant" header="Montant"></Column>
          <Column field="mode" header="Mode"></Column>
        </DataTable>
        
      </div>
      <div class="rdvPage" v-if="soldeRdvBtn">
        <DataTable :value="eleve.apointments" tableStyle="min-width: 50rem">
          <Column field="ladate" header="Date"></Column>
          <Column field="heure" header="Heure"></Column>
          <Column field="moniteur.fullname" header="Mono"></Column>
          <Column field="lieuDeb.nom" header="Début"></Column>
          <Column field="lieuFin.nom" header="Fin"></Column>
        </DataTable>
        
      </div>
    </Dialog>

    <Dialog v-model:visible="paiementBtn" modal :header="this.eleve.fullname" :style="{ width: '50%' }">
      <div class="droitsModal">
        <input type="date" name="date" id="date" v-model="paiement.date">
        <input type="number" name="montant" id="montant" v-model="paiement.montant">
        <select name="mode" id="mode" v-model="paiement.mode" class="modePaiement">
          <option value="null">Mode de paiement</option>
          <option value="ESP">ESP</option>
          <option value="CHQ">CHQ</option>
          <option value="CB">CB</option>
          <option value="VIR">VIR</option>
          <option value="PE">PE</option>
          <option value="SUBV">SUBV</option>
          <option value="CPF">CPF</option>
        </select>
        <select name="payeur" id="payeur" v-model="paiement.payeur" class="payeurSelect">
          <option value="null">Payeur</option>
          <option value="client">client</option>
          <option value="CPF">CPF</option>
          <option value="SUBV">SUBV</option>
          <option value="PE">PE</option>
        </select>
      </div>
      <template v-if="loadPaiementBtn == false">
        <button class="btn btn-primary btnValideDroit" @click="postOtherPaiement">OK PAIEMENT REALISE</button>
      </template>
      <template v-else>
        <button @click="postOtherPaiement()" class="btn btn-primary btnValideDroit" disabled><span><i class="fa fa-spinner fa-spin"></i>OK PAIEMENT REALISE</span></button>
      </template>
      
    </Dialog>

    <Dialog v-model:visible="accBtn" modal :header="this.eleve.fullname" :style="{ width: '50rem' }">
      <div class="droitsModal">
        <p>Voulez-vous passer cet élève en accéléré ?</p>
      </div>
      <button class="btn btn-primary yesBtn" @click="postAac">Oui</button>
      <button class="btn btn-danger" @click="accBtn = false;">Non</button>
    </Dialog>

    <Dialog v-model:visible="aacBtn" modal :header="this.eleve.fullname" :style="{ width: '50rem' }">
      <div class="droitsModal">
        <p>Voulez-vous passer cet élève en conduite accompagné ?</p>
      </div>
      <button class="btn btn-primary yesBtn" @click="setAac">Oui</button>
      <button class="btn btn-danger" @click="this.aacBtn = false;">Non</button>
    </Dialog>

    <Dialog v-model:visible="boiteBtn" modal :header="this.eleve.fullname" :style="{ width: '50rem' }">
      <div class="droitsModal">
        <p>Voulez-vous passer cet élève en conduite boite auto ou manuelle ?</p>
      </div>
      <button class="btn btn-primary yesBtn" @click="setBoite(1)">BA</button>
      <button class="btn btn-danger" @click="setBoite(2)">BM</button>
    </Dialog>

    <Dialog v-model:visible="dispoBoite" modal :header="this.eleve.fullname" :style="{ width: '50rem' }" @update:visible="handleClose">
      <DispoModalEleve :eleve="this.eleve" @getAllEleves="getAllEleves"/>
    </Dialog>

    <Dialog v-model:visible="showChoixEleve" modal :header="this.eleve.fullname" :style="{ width: '90rem' }" >
      <Moniteurs         
        :eleve="eleve"
        :choixDispo="eleve.choixDispo[0]"
        @getEleveInfo="getAllEleves"
        @setNewChoixDispo="setNewChoixDispoEleve"
        @closeModalChoixEleve="closeModalChoixEleve"
        :modalChoixEleve="true"
      />
    </Dialog>

    
  </div>
</template>

<script>
import SimpleTypeahead from "vue3-simple-typeahead";
import DispoModalEleve from "@/components/agence/planning/modals/DispoModalEleve.vue";
import Moniteurs from "@/components/eleve/conduite/Moniteurs.vue";

export default {
  props:["eleves", "loadEleve", "setupDispo", "changeDisplayApointment", "getEleves", "cancelEleveChoix"],
  components: {
    SimpleTypeahead,
    DispoModalEleve,
    Moniteurs
  },

  mounted() {
    this.leleves = this.eleves;
    this.getMontantAcc();
    this.getAgence();
  },

  watch: {
    eleves: function(){
      this.leleves = this.eleves;
    }
  },

  data() {
    return {
      showSimuPopup : false,
      showCancelBtnSimu : false,
      eleveSelectSimu : null,
      simuEleves : [],
      loadPaiementBtn : false,
      loadDroitBtn : false,
      showChoixEleve: false,
      auto : false,
      dispoBoite: false,
      paiement : {
        date : null,
        montant: 0,
        mode : null,
        payeur: null
      },
      showBtn : false,
      eleve : "",
      leleves : [],
      showMonoPopup: false,
      droitBtn : false,
      soldeBtn : false,
      droitNumber : 0,
      soldeSoldebtn : true,
      soldeRdvBtn: false,
      paiementBtn: false,
      accBtn: false,
      aacBtn: false,
      boiteBtn : false,

      indispoChoice : false,
      dispoChoice : false,
      resaChoice : false,
      headerSolde : "",
      montantAcc : 0,
    }
  },

  methods: {

    searchEleve(e){
      
      if(e.input.length == 3 || e.input.length == 6 || e.input.length == 9 || e.input.length == 12 || e.input.length == 15 || e.input.length == 18 || e.input.length == 21){
          window.api
          .get("/eleves/agence/planning/search/"+e.input, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            this.leleves = response.data.eleves;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    setNewChoixDispoEleve(choix){
      this.eleve.choixDispo[0] = choix;
    },

    getCodeResultSimu(e){

      window.api.get("/eleves/simulateur/"+this.$store.state.user.agence.id, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          },
        }).then(response => {
          this.simuEleves = response.data.eleves;

          let tabByResult = [];
          if(e.target.value == 'true'){
            tabByResult = this.simuEleves.filter((e) => e.code == "oui");
            this.simuEleves = tabByResult;
          }else if(e.target.value == 'false'){
            tabByResult = this.simuEleves.filter((e) => e.code == "non");
            this.simuEleves = tabByResult;
          }else{
            window.api.get("/eleves/simulateur/"+this.$store.state.user.agence.id, {
              headers: {
                "Authorization": "Bearer " + this.$store.state.token
              },
            }).then(response => {
              this.simuEleves = response.data.eleves;
            }).catch(error => {
              console.log(error)
            })
          }

        }).catch(error => {
          console.log(error)
        })


    },

    selectSimu(){
      this.eleve = this.leleves.find((element) => element.id = this.eleveSelectSimu.id);
      this.eleve.paiements = this.eleve.paiements.reverse();
      this.eleve.apointments = this.eleve.apointments.reverse();
      this.$store.commit("setEleveSelectAgence", this.eleve);
      this.eleve.choixDispo = this.eleveSelectSimu.choixDispo;
      this.showBtn = true;
      this.$emit("setupDispo", this.eleve);
      this.showSimuPopup = false;
    },

    goToHome() {
      this.$router.push({ name: "Home" });
    },

    openModalChoixEleve(){
      this.showChoixEleve = true;
    },

    closeModalChoixEleve(){
      this.showChoixEleve = false;
    },

    handleClose(){
      window.location.reload();
    },

    getAgence(){
      window.api.get("/agences/"+this.$store.state.user.agence.id, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          },
        }).then(response => {
          this.auto = response.data.autoPlanning;
        }).catch(error => {
          console.log(error)
        })
    },

    setAuto(e){
      if(e.target.checked == true){
        this.auto = true;
      }else{
        this.auto = false;
      }

      window.api
          .patch(
            "/agences/" + this.$store.state.user.agence.id,
            {
              autoPlanning: e.target.checked,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {

          })
          .catch((error) => {
            console.log(error);
          });

    },

    goToFicheEleve(){
      this.$router.push({ name: "agence-trame", params: { id:this.eleve.id }});
    },

    getMontantAcc(){
      window.api.get("/fonctionnalites/21", {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          },
        }).then(response => {
          this.montantAcc = response.data.montant;
        }).catch(error => {
          console.log(error)
        })
    },

    getAllEleves(){
      this.$emit("getEleves");
    },

    postAac(){
      window.api
        .post(
          "/permis/aac",
          {
            eleve: this.eleve.id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          if(response.data.message != "Solde insufisant"){
            this.$emit("getEleves");
            this.accBtn = false;
            this.eleve.idacc = response.data.id;
            this.eleve.acc = true;
            this.eleve.solde = this.eleve.solde - this.montantAcc;
            this.eleve.paiements.unshift({"date" : response.data.date, "prestation" : response.data.description, "montant" : response.data.montant, "mode" : "cb"})
          }else{
            alert("Solde de l'élève insufisant");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    postOtherPaiement(){
      this.loadPaiementBtn = true;
      window.api
        .post(
          "/other_paiements",
          {
            eleve: "api/utilisateurs/" + this.eleve.id,
            description: "paiement",
            totalAmount: this.paiement.montant * 100,
            mode: this.paiement.mode,
            date: this.paiement.date,
            payeur: this.paiement.payeur,
            archive: false,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.$emit("getEleves");
          this.paiement.date = null;
          this.paiement.montant = 0;
          this.paiement.mode = null;
          this.paiement.payeur = null;
          this.paiementBtn = false;
          this.eleve.solde = this.eleve.solde + this.paiement.montant;
          this.cancelChoice();
          this.loadPaiementBtn = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    choiceIndipo(){

      if(this.$store.state.planningSelectChoice == 'INDISPO'){
        this.$store.commit('setPlanningSelectChoice', "null");
      }else{
        this.$store.commit('setPlanningSelectChoice', 'INDISPO');
        this.dispoChoice = false;
        this.resaChoice = false;
      }


    },

    choiceResa(){
      if(this.$store.state.planningSelectChoice == 'RESA'){
        this.$store.commit('setPlanningSelectChoice', null);
      }else{
        this.$store.commit('setPlanningSelectChoice', 'RESA');
        this.dispoChoice = false;
        this.indispoChoice = false;
        this.$emit("changeDisplayApointment", true);
      }

    },

    choiceDispo(){

      if(this.$store.state.planningSelectChoice == 'DISPO'){
        this.$store.commit('setPlanningSelectChoice', null);
      }else{
        this.$store.commit('setPlanningSelectChoice', 'DISPO');
        this.resaChoice = false;
        this.indispoChoice = false;
        //this.$emit("changeDisplayApointment", false);
        if(this.eleve != ""){
          this.dispoBoite = true;
        }
      }
      
    },

    changeBoite(boite){
      if(window.confirm("Voulez-vous changer de type de boite cet elève ?")){
        window.api
          .patch(
            "/utilisateur/" + this.eleve.id,
            {
              boite: boite,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            this.eleve.boite = boite;
              let filter = this.leleves.filter((e) => {
                e.id == this.eleve.id;
              });
              filter.push(this.eleve);
              this.leleves = filter;
              this.boiteBtn = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    setBoite(boite){
      window.api
          .patch(
            "/utilisateur/" + this.eleve.id,
            {
              boite: boite,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            this.eleve.aac = true;
              let filter = this.leleves.filter((e) => {
                e.id == this.eleve.id;
              });
              filter.push(this.eleve);
              this.leleves = filter;
              this.boiteBtn = false;
          })
          .catch((error) => {
            console.log(error);
          });
    },

    unSetAac(){

      if(window.confirm("Voulez-vous retirer la conduite accompagné pour cet élève ?")){
        window.api
          .patch(
            "/dossiers/" + this.eleve.dossier.id,
            {
              prevoitAac: false,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            this.eleve.aac = false;
            let filter = this.leleves.filter((e) => {
              e.id == this.eleve.id;
            });
            filter.push(this.eleve);
            this.leleves = filter;
            this.aacBtn = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }else{
        this.aacBtn = false;
      }

    },

    setAac(){
      window.api
          .patch(
            "/dossiers/" + this.eleve.dossier.id,
            {
              prevoitAac: true,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            if(this.eleve.aacId == null){
              window.api
              .post(
                "/formation_aacs",
                {
                  eleve: "/api/utilisateurs/"+this.eleve.id,
                  rdvp: false,
                  rdvp1: false,
                  rdvp2: false,
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                }
              )
              .then(() => {
                this.eleve.aac = true;
                let filter = this.leleves.filter((e) => {
                  e.id == this.eleve.id;
                });
                filter.push(this.eleve);
                this.leleves = filter;
                this.aacBtn = false;
              })
              .catch((error) => {
                console.log(error);
              });
            }else{
              this.eleve.aac = true;
              let filter = this.leleves.filter((e) => {
                e.id == this.eleve.id;
              });
              filter.push(this.eleve);
              this.leleves = filter;
              this.aacBtn = false;
            }

          })
          .catch((error) => {
            console.log(error);
          });
    },

    setDroit(){
      this.loadDroitBtn = true;
      window.api
          .patch(
            "/agences/" + this.$store.state.user.agence.id,
            {
              droit: this.droitNumber,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json",
              },
            }
          )
          .then(() => {
            let u = this.$store.state.user;
            u.agence.droit = this.droitNumber;
            this.$store.commit("setUser", u);
            this.droitBtn = false;
            this.loadDroitBtn = false;
          })
          .catch((error) => {
            console.log(error);
          });
    },

    showBoite(){
      if(this.boiteBtn){
        this.boiteBtn = false;
      }else{
        this.boiteBtn = true;
      }
    },

    cancelAcc(){
      if(confirm("Voulez-vous retirer le permis accéléré à cet élève ?")){
        window.api
        .delete("/other_paiements/"+this.eleve.idacc, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then(() => {
          this.$emit("getEleves");
          this.eleve.idacc = 0;
          this.eleve.acc = false;
          
          this.eleve.solde = this.eleve.solde + this.montantAcc;
          let tab = this.eleve.paiements.filter((e) => e.prestation != "permis accéléré");
          this.eleve.paiements = tab;
        })
        .catch((error) => {
          console.log("ERRR::", error.response.data);
        });
      }

    },

    showAcc(){
      if(this.accBtn){
        this.accBtn = false;
      }else{
        this.accBtn = true;
      }
    },

    showAac(){
      if(this.aacBtn){
        this.aacBtn = false;
      }else{
        this.aacBtn = true;
      }
    },

    showPaiement(){
      if(this.paiementBtn){
        this.paiementBtn = false;
      }else{
        this.paiementBtn = true;
        
      }
    },

    changeSoldBtn(){
      if(this.soldeRdvBtn){
        this.soldeSoldebtn = true;
        this.soldeRdvBtn = false;
      }else{
        this.soldeSoldebtn = false;
        this.soldeRdvBtn = true;
      }
    },

    showSolde(){
      if(this.soldeBtn){
        this.soldeBtn = false;
      }else{
        this.soldeBtn = true;
      }
    },

    showDroits(){
      if(this.droitBtn){
        this.droitBtn = false;
      }else{
        this.droitBtn = true;
        this.droitNumber = this.$store.state.user.agence.droit;
      }
    },

    showSimu(){
      if(this.showSimuPopup){
        this.showSimuPopup = false;
      }else{
        this.showSimuPopup = true;

        window.api.get("/eleves/simulateur/"+this.$store.state.user.agence.id, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          },
        }).then(response => {
          this.simuEleves = response.data.eleves;
        }).catch(error => {
          console.log(error)
        })

      }
    },

    cancelMono(){
      this.showMonoPopup = false;
    },

    showMono(type){
      this.$store.commit("setShowMonoPopupConduite", true);
      this.$store.commit("setTypeModalMono", type);
    },

    selectItem(e){

      window.api.get("/eleves/agence/planning/selec/" + e.id, {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          },
        }).then(response => {
          this.eleve = response.data.eleves[0];
          this.eleve.paiements = this.eleve.paiements.reverse();
          this.eleve.apointments = this.eleve.apointments.reverse();
          this.$store.commit("setEleveSelectAgence", this.eleve);
          this.showBtn = true;
          this.$emit("setupDispo", this.eleve);
        }).catch(error => {
          console.log(error)
        })


    },  


    selectItemSimu(e){
      this.eleveSelectSimu = e;
  
      this.showCancelBtnSimu = true;

    },  

    cancelChoice(){
      this.eleve = "";
      this.showBtn = false;
      this.$store.commit("setEleveSelectAgence", null);
      this.$emit("cancelEleveChoix");
    },

    cancelChoiceSimu(){
      this.eleveSelectSimu = "";
      this.showCancelBtnSimu = false;

      window.api.get("/eleves/simulateur/"+this.$store.state.user.agence.id, {
              headers: {
                "Authorization": "Bearer " + this.$store.state.token
              },
            }).then(response => {
              this.simuEleves = response.data.eleves;
            }).catch(error => {
              console.log(error)
            })

    }
  },
};
</script>

<style scoped>

div.selector{
  display: flex;
  justify-content: space-around;
  margin-bottom: 1em;
}

select.modePaiement{
  width: 100%;
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  margin-right: 4em;
  background-color: #f1f1f1;
}

select.selectSimu{
  width: 30%;
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  margin-right: 4em;
  background-color: #f1f1f1;
}

select.payeurSelect{
  width: 100%;
  padding: 6px 5px;
  border: none;
  border-radius: 4px;
  margin-right: 4em;
  background-color: #f1f1f1;
}

div.headSolde{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

button.yesBtn{
  margin-right: 5em;
}
button.btnValideDroit{
  display: block;
  margin: auto;
  margin-top: 2em;
}

div.droitsModal{
  display: flex;
}

div.droitsModal input{
  margin-left : 2em;
  margin-right : 2em;
}

div.droitsModal select{
  margin-left : 2em;
  margin-right : 2em;
}
div.header {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1%;
}

div.flex {
  display: flex;
}

div.control {
  width: 32%;
  padding-top: 1em;
}

div.eleve {
  width: 20%;
  padding: 20px;
  padding-top: 2em;
}
div.button button {

  color: white;
  opacity: 1;
  margin: 10px;
}

div.select button.btn {
  color: white;
  opacity: 1;
  padding-left: 20px;
  padding-right: 20px;
  margin: 10px;
}

div.select button.btn2 {
  background: #2e9ea3 0% 0% no-repeat padding-box;
  border-radius: 14px;
  color: white;
  opacity: 1;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
}

.hidding{
  visibility: hidden;
}
div.containername{
  display: flex;
}

img.logoPaiement {
  width: 20px;
}

img.gohome{
  margin-top: 2%;
  width: 5%;
  height: 5%;
}

</style>
