
<template>
  <div id="Conduite" class="row">
    <div id="fullCalendar" @click="ordreVueMobile" class="col-12">
      <FullCalendar :options="calendarOptions" ref="fullCalendar"/>
    </div>
    <!-- The Modal -->
    <div class="modal">
      <!-- Modal content -->
      <div class="modal-content">
        <div class="modal-header">
          <span id="monoCancelResa" class="close mono" @click="closeModalMono">&times;</span>
          <h2>Rendez-vous</h2>
        </div>
        <div class="modal-body">
          <div class="contentmodalclose">
            <template v-if="choixDateResa != false">
              <router-link :to="{ name: 'mono-trame', params: { rdvId: this.idRdv, id: this.ideleve } }"><button
                  v-if="!choixDateResa['event']['_def']['extendedProps']['done']">Accéder à la trame</button><button
                  v-if="choixDateResa['event']['_def']['extendedProps']['done']">Modifier la trame</button>
              </router-link>
              <div v-if="!choixDateResa['event']['_def']['extendedProps']['done']">
                <select id="selectTypeMotiff">
                  <option value="choix">Choix un type de motif</option>
                  <template v-for="(type, index) in typeMotif" :key="index">
                    <option :value="type.id">{{ type.label }}</option>
                  </template>
                </select>
                <input type="text" name="lemotif" id="" placeholder="Saisir le motif" v-model="motif">
                <button @click="validerCancel">Annuler le cours</button>
              </div>
            </template>

          </div>
        </div>
        <div class="modal-footer"></div>
      </div>

    </div>

    <!-- The Modal -->
    <div id="modalJIndispo" class="modal">
      <!-- Modal content -->
      <div class="modal-content">
        <div class="modal-header">
          <span id="monoCancelJInspo" class="close mono" @click="closeModalMono">&times;</span>
          <h2>J-Indispo</h2>
        </div>
        <div class="modal-body">
          <div class="contentmodalclose">
            <div>
              <input type="text" name="lemotif" placeholder="Saisir le motif" v-model="motif">
              <button @click="validerJIndispo">Annuler les cours</button>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>

    </div>


    <div id="choixResa" v-if="choixDateResa != false">

      <div id="resaCours" class="pt-5">
        <h2>
          {{ this.choixDateResa.event["_def"]["title"] }}
        </h2>
        <p>{{this.choixDateResa.event["_def"]["extendedProps"]["heure"]}}</p>
        <router-link :to="{ name: 'MonoTrame', params: { rdvId: this.idRdv, id: this.ideleve } }"><Button id="btnCours"
            class="mt-5" v-if="!choixDateResa['event']['_def']['extendedProps']['done']">Commencer le
            cours</Button><Button v-if="choixDateResa['event']['_def']['extendedProps']['done']">Modifier le
            cours</Button></router-link>
      </div>

      <hr class="solid">

      <div class="mt-5" v-if="!choixDateResa['event']['_def']['extendedProps']['done']">
        <template v-for="(type, index) in typeMotif" :key="index">
          <div><Button @click="setMotif(type)"
              class="mt-5 btnTypeMotif buttonsMotif">{{ type.label }}</Button></div>
        </template>
        <br>
        <input type="text" name="lemotif" id="" placeholder="Saisir le motif" v-model="motif">
        <div id="btnOptions" class="mt-5 pb-5">
          <Button id="btnRetour" @click="ordreVueMobile2">Retour</Button>
          <Button id="btnValidation" @click="validerCancel">Valider</Button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment';

//import axios from 'axios'

export default {
  name: 'Conduite',
  components: {
    FullCalendar, // make the <FullCalendar> tag available

  },

  data() {
    return {
      nextBtnPress : false,
      todayBtnActive : false,
      typeMotifchoix: '',
      typeMotifchoixId: '',
      expanded: false,
      adresse: "",
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: 'timeGridDay',
        nowIndicator: true,
        eventResizableFromStart: true,
        firstDay: 1,
        allDaySlot: true,
        slotMinTime: "07:00:00",
        slotMaxTime: "21:00:00",
        height: "auto",
        locale: "fr",
        selectable: true,
        timeZone: "local",
        headerToolbar: {
          start: "prev,next today",
          center: "title",
          end: "timeGridWeek,timeGridDay",
        },
        buttonText: {
          today: "Aujourd'hui",
          prev: "<",
          next: ">"
        },

        customButtons:{
          hideSunday: {
            text: 'Dimanche',
            click: (e) => this.hideSundayMethod(e),
            background: "red"
          },
          prev: {
            // this overrides the prev button
            text: "PREV",
            click: () => {
              this.todayBtnActive = false;
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.prev();

              let date = moment(calendarApi.getDate().toString()).format(
                "YYYY-MM-DDTHH:mm:ss"
              );
              if(this.dayWeekPlanning == false){
                this.getMoniteurChange(date, false);
              }else{
                this.getMoniteurChange(date, true);
              }

              let resultIndispo = this.calendarOptions.events.filter(
                (e) => e.type != "indispo"
              );
              this.calendarOptions.events = resultIndispo;

                if(this.dayWeekPlanning == false){
                  if(this.calendarOptions.events != null){
                    this.calendarOptions.events.forEach((e) => {
                      if(e.type == "boite"){
                          if(calendarApi.getDate().getDay() == 0){
                            let start = moment(e.start).subtract(1, "week");
                            let end = moment(e.end).subtract(1, "week");
                            e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                            e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                          }else{
                            if(this.nextBtnPress){
                              let start = moment(e.start).subtract(1, "week");
                              let end = moment(e.end).subtract(1, "week");
                              e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                              e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                              this.nextBtnPress = false;
                            }
                          }
                      }
                    });
                  }
                }else{
                  if(this.calendarOptions.events != null){
                    this.calendarOptions.events.forEach((e) => {
                      if(e.type == "boite"){
                        let start = moment(e.start).subtract(1, "week");
                        let end = moment(e.end).subtract(1, "week");
                        e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                        e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                      }
                    });
                  }
                }

            },
          },
          next: {
            // this overrides the next button
            text: "NEXT",
            click: () => {
              this.todayBtnActive = false;
              this.nextBtnPress = true;
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.next();

              let date = moment(calendarApi.getDate().toString()).format(
                "YYYY-MM-DDTHH:mm:ss"
              );

              if(this.dayWeekPlanning == false){
                this.getMoniteurChange(date, false);
              }else{
                this.getMoniteurChange(date, true);
              }

              let resultIndispo = this.calendarOptions.events.filter(
                (e) => e.type != "indispo"
              );
              this.calendarOptions.events = resultIndispo;

                if(this.dayWeekPlanning == false){
                  if(this.calendarOptions.events != null){
                    this.calendarOptions.events.forEach((e) => {
                      if(e.type == "boite"){
                          if(moment(e.start).add(1, 'day').format("YYYY-MM-DD") == moment(calendarApi.getDate().toString()).format("YYYY-MM-DD")){
                            let start = moment(e.start).add(1, "week");
                            let end = moment(e.end).add(1, "week");
                            e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                            e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                          }
                      }

                    });
                  }
                }else{
                  if(this.calendarOptions.events != null){
                    this.calendarOptions.events.forEach((e) => {
                      if(e.type == "boite"){
                        let start = moment(e.start).add(1, "week");
                        let end = moment(e.end).add(1, "week");
                        e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                        e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                      }

                    });
                  }
                }

            },
          },

          today: {
            // this overrides the next button
            text: "Aujourd'hui",
            click: () => {
              this.todayBtnActive = true;
              this.nextBtnPress = false;
              let calendarApi = this.$refs.fullCalendar.getApi();
              if(this.calendarOptions.events != null){
                this.calendarOptions.events = [];
                let today = new Date();
                let diff =
                  today.getDate() -
                  today.getDay() +
                  (today.getDay() === 0 ? -6 : 1);
                let monday = new Date(today.setDate(diff));
                let date = moment(monday).format("YYYY-MM-DDTHH:mm:ss");
                if(this.dayWeekPlanning == false){
                  this.getMoniteurChange(moment(new Date()).format("YYYY-MM-DDT00:00:00"), false);
                }else{
                  this.getMoniteurChange(date, true);
                }
              }

              calendarApi.today();
            },
          },

          timeGridDay: {
            // this overrides the next button
            text: "day",
            click: () => {
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.changeView('timeGridDay');
              calendarApi.today();
              this.dayWeekPlanning = false;
              this.todayBtnActive = true;
              this.nextBtnPress = false;
              this.getMoniteurChange(moment(new Date()).format("YYYY-MM-DDT00:00:00"), false);
            },
          },

          timeGridWeek: {
            // this overrides the next button
            text: "week",
            click: () => {
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.changeView('timeGridWeek');
              calendarApi.today();
              this.dayWeekPlanning = true;
              this.todayBtnActive = true;
              this.nextBtnPress = false;
              if(this.calendarOptions.events != null){
                this.calendarOptions.events = [];
                let today = new Date();
                let diff =
                  today.getDate() -
                  today.getDay() +
                  (today.getDay() === 0 ? -6 : 1);
                let monday = new Date(today.setDate(diff));
                let date = moment(monday).format("YYYY-MM-DDTHH:mm:ss");
                if(this.dayWeekPlanning == false){
                  this.getMoniteurChange(moment(new Date()).format("YYYY-MM-DDT00:00:00"), false);
                }else{
                  this.getMoniteurChange(date, true);
                }
              }
            },
          },


        },


        events: [],
        select: this.select,
        eventClick: this.clickEvent,
        editable: true,
        selectAllow: this.selectAllow,
        eventChange: this.putDispoEleve,
        eventDidMount: this.eventhover,
      },
      moniteur: [],
      typeMotif: [],
      motif: "",
      choixDateResa: false,
      idRdv: false,
      ideleve: false,
      rdvjIndispo: [],
      lajdinspo: [],
      dayWeekPlanning : false
    }

  },
  mounted() {
    this.getMoniteur()
    this.getTypeMotif()
  },
  methods: {

    setMotif(type){
      for(let b of document.getElementsByClassName("btnTypeMotif")){
        if(b.innerText == type.label){
          if(b.classList.contains("buttonsMotifActive")){
            b.classList.remove("buttonsMotifActive");
            b.classList.add("buttonsMotif");
            this.typeMotifchoix = "";
            this.typeMotifchoixId = "";
          }else{
            b.classList.remove("buttonsMotif");
            b.classList.add("buttonsMotifActive");
            this.typeMotifchoix = type.label;
            this.typeMotifchoixId = type.id;
          }
        }else{
          b.classList.remove("buttonsMotifActive");
          b.classList.add("buttonsMotif");
        }
      }
    },

    eventhover(info) {
      info.el.title = info.event.title;
    },

    validerJIndispo() {
      window.api.post("/motifs", {
        typeMotif: "api/type_motifs/3",
        label: this.motif
      },
        {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          },
        }).then(response => {
          let now = new Date;
          let now2 = new Date;
          now.setHours(now.getHours() + 1)
          this.rdvjIndispo.forEach(rdv => {
            window.api.patch("/apointments/" + rdv.id, {
              motif: response.data["@id"],
              cancelRdv: now
            },
              {
                headers: {
                  "Authorization": "Bearer " + this.$store.state.token,
                  "Content-Type": "application/merge-patch+json"
                },
              }).then(res => {
                let resa = this.calendarOptions.events.find(event => {
                  if (event.id == res.data.id && event.type == "resa") {
                    return true
                  }
                })
                resa.color = "grey";
                resa.motif = "Mono Absent";
                let resultResa = this.calendarOptions.events.filter(event => {
                  if (event.id != res.data.id && event.type == "resa") {
                    return true
                  }
                })
                resultResa.push(resa)
                let resultIndispo = this.calendarOptions.events.filter(event => {
                  if (event.type == "Indispo") {
                    return true
                  }
                })

                let result = []

                resultResa.forEach(resResa => {
                  result.push(resResa)
                })

                resultIndispo.forEach(resIndispo => {
                  result.push(resIndispo)
                })

                this.calendarOptions.events = result

              }).catch(error => {
                console.log(error)
              })
          })

          now.setHours(7);
          now.setMinutes(0);
          now.setSeconds(0);
          now2.setHours(21);
          now2.setMinutes(0);
          now2.setSeconds(0);
          window.api.post("/indisponibilites", {
            start: moment(this.lajdinspo.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(this.lajdinspo.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            moniteur: "api/moniteurs/" + this.moniteur.idMonos,
          },
            {
              headers: {
                "Authorization": "Bearer " + this.$store.state.token
              },
            }).then(response => {
              this.calendarOptions.events.push({ id: response.data.id, title: "INDISPO", start: moment(this.lajdinspo.startStr).format("YYYY-MM-DDTHH:mm:ss"), end: moment(this.lajdinspo.endStr).format("YYYY-MM-DDTHH:mm:ss"), color: "grey", type: "Indispo", "motif": false })
            }).catch(error => {
              console.log(error)
            })
        }).catch(error => {
          console.log(error)
        })
      document.getElementById("modalJIndispo").style.display = "none";
    },

    jIndispo(e) {

      let now = new Date(e.start);
      this.moniteur.rdv.forEach(ap => {
        let dateStart = new Date(ap.start.date);
        if (now.getDay() == dateStart.getDay() && now.getMonth() == dateStart.getMonth() && now.getFullYear() == dateStart.getFullYear()) {
          this.rdvjIndispo.push(ap);
        }
      })
      if (this.rdvjIndispo.length != 0) {
        document.getElementById("modalJIndispo").style.display = "block";
        this.lajdinspo = e;

      } else {
        window.api.post("/indisponibilites", {
          start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
          end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
          moniteur: "api/moniteurs/" + this.moniteur.idMonos,
        },
          {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
            this.calendarOptions.events.push({ id: response.data.id, title: "INDISPO", start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"), end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"), color: "grey", type: "Indispo", "motif": false })
          }).catch(error => {
            console.log(error)
          })
      }

    },

    validerCancel() {

      let typemot = this.typeMotifchoix;

      if (this.motif == "") {
        this.motif = typemot;
      }

      window.api.post("/motifs", {
        typeMotif: "api/type_motifs/" + this.typeMotifchoixId,
        label: this.motif
      },
        {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          },
        }).then(response => {
          let now = new Date
          now.setHours(now.getHours() + 1)
          window.api.patch("/apointments/" + this.choixDateResa.event["_def"]["publicId"], {
            motif: response.data["@id"],
            cancelRdv: now
          },
            {
              headers: {
                "Authorization": "Bearer " + this.$store.state.token,
                "Content-Type": "application/merge-patch+json"
              },
            }).then(res => {
              let resa = this.calendarOptions.events.find(event => {
                if (event.id == res.data.id && event.type == "resa") {
                  return true
                }
              })

              if (typemot == "Mono Absent") {
                resa.color = "grey";
                resa.motif = "Mono Absent";
              } else if (typemot == "Incident") {
                resa.color = "yellow";
                resa.motif = "Incident";
                resa.textColor = "black"
              }

              let resultResa = this.calendarOptions.events.filter(event => {
                if (event.id != res.data.id && event.type == "resa") {
                  return true
                }
              })


              let resultIndispo = this.calendarOptions.events.filter(event => {
                if (event.type == "Indispo") {
                  return true
                }
              })

              let result = []

              if (typemot != "Eleve Absent" && typemot != "Autre") {
                resultResa.push(resa)
              }

              resultResa.forEach(resResa => {
                result.push(resResa)
              })

              resultIndispo.forEach(resIndispo => {
                result.push(resIndispo)
              })


              this.calendarOptions.events = result
              this.choixDateResa = false;
              document.getElementById("fullCalendar").style.display =
                "initial";

            }).catch(error => {
              console.log(error)
            })


        }).catch(error => {
          console.log(error)
        })
    },

    getTypeMotif() {
      window.api.get("/type_motifs", {
        headers: {
          "Authorization": "Bearer " + this.$store.state.token
        },
      }).then(response => {
        this.typeMotif = response.data["hydra:member"]
      }).catch(error => {
        console.log(error)
      })
    },

    closeModalMono(e) {
      if (e.target.id === "monoCancelResa") {
        document.getElementById("modalResaMono").style.display = "none";
      } else if (e.target.id === "monoCancelJInspo") {
        document.getElementById("modalJIndispo").style.display = "none";
      }
    },

    clickEvent(e) {



      if (e.event["_def"].extendedProps.type == "Indispo") {
        window.api.delete("/indisponibilites/" + e.event["_def"].publicId,
          {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(() => {
            let res = this.calendarOptions.events.filter(event => {
              return event.id != e.event["_def"].publicId;
            })
            this.calendarOptions.events = res;
          }).catch(error => {
            console.log(error)
          })
      } else if (e["event"]["_def"]["extendedProps"]["type"] == "resa" && e["event"]["_def"]["extendedProps"]["motif"] == false) {
        if (e["event"]["_def"]["extendedProps"]["done"] == false) {
          this.choixDateResa = e;
          this.idRdv = e.event["_def"].publicId;
          this.ideleve = e.event["_def"]["extendedProps"]["ideleve"];

        } else {
          let now = new Date();
          let startDate = new Date(Date.parse(e["event"]["_def"]["extendedProps"]["doneDate"]["date"]));
          let endDate = new Date(startDate.setDate(startDate.getDate() + 1))
          if (now < endDate) {
            this.choixDateResa = e;
            this.idRdv = e.event["_def"].publicId;
            this.ideleve = e.event["_def"]["extendedProps"]["ideleve"];
          }
        }

      }
    },

    getMoniteurChange(date, planningForm){
      window.api.post("/moniteur/"+this.$store.state.user.id+"/changeplan", {
          date : date,
          planningForm: planningForm
        },
          {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
            if (this.calendarOptions.events.length != 0) {
              let filterEvent = this.calendarOptions.events.filter(
                (e) => e.type != "resa"
              );
              this.calendarOptions.events = filterEvent;
            }
            this.moniteur = response.data.moniteur[0];
            this.moniteur.indispos.forEach(indispo => {
              this.calendarOptions.events.push({ id: indispo.id, start: indispo.start.date, end: indispo.end.date, title: "INDISPO", color: "grey", type: "Indispo" })
            })

            this.moniteur.rdv.forEach(app => {

              let statAac = "";

              if(app.statAac != "null"){
                statAac = app.statAac;
              }

              if (app.motif.type == "Mono Absent") {
                this.calendarOptions.events.push({ id: app.id, title: statAac + " " + app.eleve +"  deb : " + app.lieuDeb.nom + "/ fin :"+app.lieuFin.nom + " MONO ABS", start: moment(app.start.date).format("YYYY-MM-DDTHH:mm:ss"), end: moment(app.end.date).format("YYYY-MM-DDTHH:mm:ss"), color: "grey", type: "resa", done: app.done, "editable": false, "motif": "Mono Absent", "heure" : app.heure })
              } else if (app.motif.type == "Incident") {
                this.calendarOptions.events.push({ id: app.id, title: statAac + " " +  app.eleve +"  deb : " + app.lieuDeb.nom + "/ fin :"+app.lieuFin.nom, start: moment(app.start.date).format("YYYY-MM-DDTHH:mm:ss"), end: moment(app.end.date).format("YYYY-MM-DDTHH:mm:ss"), color: "yellow", textColor: "black", type: "resa", done: app.done, "editable": false, "motif": "Incident", "heure" : app.heure })
              } else if (app.motif == "null") {
                if (app.done == true) {
                  this.calendarOptions.events.push({ id: app.id, title: statAac + " " +  app.eleve +"  deb : " + app.lieuDeb.nom + "/ fin :"+app.lieuFin.nom, start: moment(app.start.date).format("YYYY-MM-DDTHH:mm:ss"), end: moment(app.end.date).format("YYYY-MM-DDTHH:mm:ss"), color: "teal", type: "resa", done: app.done, "editable": false, "motif": false, ideleve: app.ideleve, doneDate: app.doneDate, "heure" : app.heure })
                } else {

                  let color = "green";

                  if(app.boite == "BM"){
                    color = "#ED7742";
                  }

                  this.calendarOptions.events.push({ id: app.id, title: statAac + " " + app.eleve +"  deb : " + app.lieuDeb.nom + "/ fin :"+app.lieuFin.nom, start: moment(app.start.date).format("YYYY-MM-DDTHH:mm:ss"), end: moment(app.end.date).format("YYYY-MM-DDTHH:mm:ss"), color: color, type: "resa", done: app.done, "editable": false, "motif": false, ideleve: app.ideleve, "heure" : app.heure })
                }

              }
            })

            if (this.todayBtnActive == true){
              this.moniteur.params.forEach((i) => {
                let today = new Date();
                let todayDay = today.getDay();
                let startDate = new Date(i.start);
                let endDate = new Date(i.end);
                let startDay = startDate.getDay();
                let endDay = endDate.getDay();
                let diffStart = today.getDate() - todayDay + (todayDay == 0 ? -6 : startDay); // permet de récupérer un jour précis de la semaine actuel en mettant l'id du jour (0 = dimanche 1 = lundi...)
                let diffEnd = today.getDate() - todayDay + (todayDay == 0 ? -6 : endDay); // permet de récupérer un jour précis de la semaine actuel en mettant l'id du jour (0 = dimanche 1 = lundi...)

                startDate = new Date(today.setDate(diffStart));
                endDate = new Date(today.setDate(diffEnd));

                if(startDay == 0){
                  startDate = startDate.setDate(startDate.getDate() + 1 * 7);
                  startDate = new Date(startDate);
                }

                if(endDay == 0){
                  endDate = endDate.setDate(endDate.getDate() + 1 * 7);
                  endDate = new Date(endDate);
                }

                let ddStart = String(startDate.getDate()).padStart(2, '0');
                let ddEnd = String(endDate.getDate()).padStart(2, '0');
                let mm = String(today.getMonth() + 1).padStart(2, '0');
                let yyyy = today.getFullYear();

                let start = yyyy + "-" + mm + "-" + ddStart + "T" + i.start.split("T")[1];
                let end = yyyy + "-" + mm + "-" + ddEnd + "T" + i.end.split("T")[1];

                if(i.title == "INDISPO"){
                  this.calendarOptions.events.push({
                    id: i.id,
                    title: "INDISPO",
                    start: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
                    end: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
                    color: "#494949FF",
                    type: "boite",
                    motif: false,
                  });
                }else{
                  this.calendarOptions.events.push({
                    id: i.id,
                    title: i.title,
                    start: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
                    end: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
                    color: i.color,
                    type: "boite",
                    motif: false,
                    display: "background",
                  });
                }


              });
            }

          }).catch(error => {
            console.log(error)
          })
    },

    getMoniteur() {
      window.api.get("/moniteur/" + this.$store.state.user.id,
        {
          headers: {
            "Authorization": "Bearer " + this.$store.state.token
          },
        }).then(response => {
          if (this.calendarOptions.events.length != 0) {
            let filterEvent = this.calendarOptions.events.filter(
              (e) => e.type != "resa"
            );
            this.calendarOptions.events = filterEvent;
          }
          this.moniteur = response.data.moniteur[0];
          this.moniteur.indispos.forEach(indispo => {
            this.calendarOptions.events.push({ id: indispo.id, start: indispo.start.date, end: indispo.end.date, title: "INDISPO", color: "grey", type: "Indispo" })
          })

          this.moniteur.rdv.forEach(app => {

            let statAac = "";

            if(app.statAac != "null"){
              statAac = app.statAac;
            }

            if (app.motif.type == "Mono Absent") {
              this.calendarOptions.events.push({ id: app.id, title: statAac + " " + app.eleve +"  deb : " + app.lieuDeb.nom + "/ fin :"+app.lieuFin.nom + " MONO ABS", start: moment(app.start.date).format("YYYY-MM-DDTHH:mm:ss"), end: moment(app.end.date).format("YYYY-MM-DDTHH:mm:ss"), color: "grey", type: "resa", done: app.done, "editable": false, "motif": "Mono Absent", "heure" : app.heure })
            } else if (app.motif.type == "Incident") {
              this.calendarOptions.events.push({ id: app.id, title: statAac + " " +  app.eleve +"  deb : " + app.lieuDeb.nom + "/ fin :"+app.lieuFin.nom, start: moment(app.start.date).format("YYYY-MM-DDTHH:mm:ss"), end: moment(app.end.date).format("YYYY-MM-DDTHH:mm:ss"), color: "yellow", textColor: "black", type: "resa", done: app.done, "editable": false, "motif": "Incident", "heure" : app.heure })
            } else if (app.motif == "null") {
              if (app.done == true) {
                this.calendarOptions.events.push({ id: app.id, title: statAac + " " +  app.eleve +"  deb : " + app.lieuDeb.nom + "/ fin :"+app.lieuFin.nom, start: moment(app.start.date).format("YYYY-MM-DDTHH:mm:ss"), end: moment(app.end.date).format("YYYY-MM-DDTHH:mm:ss"), color: "teal", type: "resa", done: app.done, "editable": false, "motif": false, ideleve: app.ideleve, doneDate: app.doneDate, "heure" : app.heure })
              } else {

                let color = "green";

                if(app.boite == "BM"){
                  color = "#ED7742";
                }

                this.calendarOptions.events.push({ id: app.id, title: statAac + " " + app.eleve +"  deb : " + app.lieuDeb.nom + "/ fin :"+app.lieuFin.nom, start: moment(app.start.date).format("YYYY-MM-DDTHH:mm:ss"), end: moment(app.end.date).format("YYYY-MM-DDTHH:mm:ss"), color: color, type: "resa", done: app.done, "editable": false, "motif": false, ideleve: app.ideleve, "heure" : app.heure })
              }

            }
          })
          if (this.dayWeekPlanning == false){
            this.moniteur.params.forEach((i) => {

              let today = new Date();
              let todayDay = today.getDay();
              let startDate = new Date(i.start);
              let endDate = new Date(i.end);
              let startDay = startDate.getDay();
              let endDay = endDate.getDay();
              let diffStart = today.getDate() - todayDay + (todayDay == 0 ? -6 : startDay); // permet de récupérer un jour précis de la semaine actuel en mettant l'id du jour (0 = dimanche 1 = lundi...)
              let diffEnd = today.getDate() - todayDay + (todayDay == 0 ? -6 : endDay); // permet de récupérer un jour précis de la semaine actuel en mettant l'id du jour (0 = dimanche 1 = lundi...)

              startDate = new Date(today.setDate(diffStart));
              endDate = new Date(today.setDate(diffEnd));

              if(startDay == 0){
                startDate = startDate.setDate(startDate.getDate() + 1 * 7);
                startDate = new Date(startDate);
              }

              if(endDay == 0){
                endDate = endDate.setDate(endDate.getDate() + 1 * 7);
                endDate = new Date(endDate);
              }

              let ddStart = String(startDate.getDate()).padStart(2, '0');
              let ddEnd = String(endDate.getDate()).padStart(2, '0');
              let mm = String(today.getMonth() + 1).padStart(2, '0');
              let yyyy = today.getFullYear();

              let start = yyyy + "-" + mm + "-" + ddStart + "T" + i.start.split("T")[1];
              let end = yyyy + "-" + mm + "-" + ddEnd + "T" + i.end.split("T")[1];


              if(i.title == "INDISPO"){
                this.calendarOptions.events.push({
                  id: i.id,
                  title: "INDISPO",
                  start: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
                  end: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
                  color: "#494949FF",
                  type: "boite",
                  motif: false,
                });
              }else{
                this.calendarOptions.events.push({
                  id: i.id,
                  title: i.title,
                  start: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
                  end: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
                  color: i.color,
                  type: "boite",
                  motif: false,
                  display: "background",
                });
              }


            });
          }



        }).catch(error => {
          console.log(error)
        })
    },

    select(e) {

      if (e.allDay == false) {
        window.api.post("/indisponibilites", {
          start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
          end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
          moniteur: "api/moniteurs/" + this.moniteur.idMonos,
        },
          {
            headers: {
              "Authorization": "Bearer " + this.$store.state.token
            },
          }).then(response => {
            this.calendarOptions.events.push({ id: response.data.id, title: "INDISPO", start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"), end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"), color: "grey", type: "Indispo", "motif": false })
          }).catch(error => {
            console.log(error)
          })
      } else {
        this.jIndispo(e);
      }

    },

    selectAllow(select) {
      return moment().diff(select.start) <= 0;
    },


    ordreVueMobile() {
      if (typeof this.choixDateResa.event != "undefined") {
        if (!this.choixDateResa['event']['_def']['extendedProps']['done'] || this.choixDateResa["event"]["_def"]["extendedProps"]["motif"] == false) {
          document.getElementById("fullCalendar").style.display = "none";
          document.getElementById("Conduite").style.background = "none";
        }
      }

    },

    ordreVueMobile2(e) {
      if (e.target.id == "btnRetour") {
        this.choixDateResa = false;
        document.getElementById("fullCalendar").style.display =
          "initial";
      }
    },

  },
}
</script>

<style scoped>
div.contentmodalclose {
  display: flex;
}

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */

  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  -webkit-animation-name: fadeIn;
  /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s
}

/* Modal Content */
.modal-content {

  margin: auto;
  margin-top: 5em;
  background-color: #fefefe;
  width: 50%;

  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #93C2FF;
  color: white;
}

.modal-body {
  padding: 2px 16px;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #FFFFFF;
  color: white;
}

/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0
  }

  to {
    bottom: 0;
    opacity: 1
  }
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0
  }

  to {
    bottom: 0;
    opacity: 1
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes fadeIn {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

#fullCalendar {
  margin: auto;
  width: 80%;
  margin-top: 2%;
}

#Conduite {
  width: 100%;
  height: auto;
  background: url('../../assets/images/fond2.png');
  min-height: 100vh;
  height: auto;
  background-size: cover;
  background-position: center, center;


}

#choixResa {
  height: auto;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
  background: url('../../assets/images/fond2.png');
  min-height: 100vh;
  background-size: cover;
  background-position: center, center;

}

hr {
  width: 100%;
  margin-top: 4%;
  border-top: 3px solid orange;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.buttonsMotif {
  color: rgb(0, 0, 0) !important;
  background-color: white !important;
  width: 155px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.buttonsMotifActive {
  color: white !important;
  background-color: #07989F !important;
  width: 155px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

#btnValidation {
  background-color: #DE6FA8;
  color: white;
  width: 120px;

}

#btnRetour {
  background-color: #DE6FA8;
  color: white;
  width: 120px;
}

#btnCours {
  background-color: #07989F;
  color: white;
  width: 200px;

}

#btnOptions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

}













/* Mobile Styles */
@media only screen and (max-width: 400px) {



  hr {
    width: 100%;
    margin-top: 15%;
    border-top: 3px solid orange;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }


}


/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {



  hr {
    width: 100%;
    margin-top: 15%;
    border-top: 3px solid orange;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }


}


/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {


  #choixResa {
    padding: 15%;
  }

  hr {
    width: 100%;
    margin-top: 15%;
    border-top: 3px solid orange;
  }


  /* Styles */
}
</style>


<style>
/* Mobile Styles */
@media only screen and (max-width: 400px) {
  .fc .fc-toolbar-title {
    display: none !important;
  }


}


/* Tablet Styles */
@media only screen and (min-width: 401px) and (max-width: 960px) {

  .fc .fc-toolbar-title {
    display: none !important;
  }
}


/* iPads (landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {

  .fc .fc-toolbar-title {
    display: none !important;
  }

  /* Styles */
}
</style>